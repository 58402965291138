const folderName = "conversion_factors";

export const getConvFactRef = (state, trackerId) => {
  //debugger
  let tracker = state.getInPath(`entities.trackers.byId.${trackerId}`);
  // Get the alternative root ref with tree_name
  let altMasters = tracker.get("alt_root_masters");
  if (!altMasters) {
    return null;
  }
  for (let [branchId, crefId] of altMasters.entrySeq()) {
    let cref = state.getInPath(`entities.refs.byId.${crefId}`);
    //console.log("CHECKING CONV FACTOR BRANCH REF", branchId, crefId, cref)
    if (cref && cref.getInPath("latest_commit.tree_root.name") == folderName) {
      //console.log("FOUND REF!!!!!")
      return cref;
    }
  }
  //console.log("CONV FACTOR BRANCH REF NOT FOUND")
  return null;
};

export const loadConvFacts = (state, commitId) => {
  if (!commitId) {
    console.log("Aborted loading conversion factors.  Must provide a commitId");
    return null;
  }

  console.log("Loading and generating map for conversion factors on commit", commitId);
  let t0 = performance.now();

  // Get the conversion factor objects
  let conversionFactors = state.getInPath(`entities.commitEdges.byId.${commitId}.conversionFactors`);

  // Transform these into a dictionary of metricId: {object}
  let convFactorMap = null;
  if (conversionFactors) {
    convFactorMap = {};
    for (let [cfId, cf] of conversionFactors) {
      let metricId = cf.get("metric");
      let toUnit = cf.get("toUnit");
      cf = cf.set("metric", state.getInPath(`entities.baseMetrics.byId.${metricId}`));
      convFactorMap[[metricId, toUnit]] = cf;
    }
  }

  console.log("Conversion factors loaded", performance.now() - t0, "ms");

  // If we have a Ref but not conversion factors then
  return convFactorMap;
};
