import React from "react";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
//import { getAllOps } from "traec/utils/metricOperations";
import Octicon from "react-octicon";
import "./tooltips.css";
import Traec from "traec";
import { map } from "jquery";
const Im = Traec.Im;

const isStr = (i) => typeof i === "string" || i instanceof String;

const flattenOperations = (op, ops = Traec.Im.List()) => {
  op = Im.fromJS(op);
  // console.log(" - running flattenOperations", op?.toJS())
  if (isStr(op)) {
    return op;
  }
  if (Im.isMap(op)) {
    if (!op.size || !op?.get("args")?.size) {
      return ops;
    }
    let _args = op.get("args").map((i) => {
      if (Im.isMap(i)) {
        // console.log(" - flattenOperations going down", i?.toJS())
        ops = flattenOperations(i, ops);
        // console.log(" - flattenOperations going up", ops?.toJS())
        return null;
      }
      return i;
    });
    return ops.unshift(
      op.set(
        "args",
        _args.filter((i) => i)
      )
    );
  }
};

export const renderOps = (ops, baseMetrics) => {
  return ops.map((op) => {
    let metricIds = op.get("args").filter((i) => isStr(i));
    let renderedMetrics = metricIds
      .map((id) => baseMetrics.get(id))
      .filter((i) => i)
      .map((bm, i) => <BaseMetricListItem key={i} bm={bm} />);
    return op.merge({ metricIds, renderedMetrics });
  });
};

export const getMetricsFromIndicator = ({ indicator, baseMetrics }) => {
  let _json = indicator.get("operation_json");
  let ops = flattenOperations(_json);
  console.log("Flattened indicator operations", _json?.toJS(), ops?.toJS());
  // Get the overall factor
  let factor = ops.first().get("operation") == "mult" ? ops.first().get("args").first() || 1 : 1; //ops[0].args[0];
  // Append the list of metricComponents to the operations
  let _ops = ops.first().get("operation") == "mult" ? ops.slice(1) : ops;
  ops = renderOps(_ops.reverse(), baseMetrics);
  console.log("Returning getMetricsFromIndicator", ops?.toJS());
  return { factor, ops };
};

export function TargetValueDisplay({ targetValue, raThreshold, greenBelow }) {
  if (targetValue == 0) {
    // If the target value is set to 0, display 0 rather than empty/null values in the row.
    targetValue = 0;
  } else if (!targetValue) {
    return null;
  }
  let arrowUp = raThreshold ? raThreshold < targetValue : !greenBelow;
  let arrow = arrowUp ? <Octicon name="arrow-up" /> : <Octicon name="arrow-down" />;
  return (
    <React.Fragment>
      {arrow} {targetValue} {raThreshold ? `| ${raThreshold}` : null}
    </React.Fragment>
  );
}

export function OperationMetrics({ ops, numerator = true }) {
  let lists = [];
  let validOps = ops.filter((i) => i.exponent > 0 === numerator && i.renderedMetrics.length);
  let multipleSets = validOps.length > 1;
  let counter = 0;
  for (let op of validOps) {
    lists.push(
      <React.Fragment key={counter}>
        {multipleSets ? (
          <p className="m-0 p-0">
            <b>{Math.sign(op.factor) > 0 ? "Add" : "Subtract"}</b>
          </p>
        ) : null}
        <ul style={{ margin: 0, padding: 0 }}>{op.renderedMetrics}</ul>
      </React.Fragment>
    );
    counter += 1;
  }

  if (!numerator && lists.length === 0) {
    lists = "1";
  }

  return <React.Fragment>{lists}</React.Fragment>;
}

export const IndicatorEqn = (props) => {
  if (props.outOfScope) {
    // Render 'Out of Scope' on indicators in the indicator panel that are not displayed in the project overview panel -- Jira: ASS-260
    return (
      <ul style={{ margin: 0, padding: 0, color: "grey" }}>
        Out of Scope
        <OperationMetrics ops={props.ops} />
      </ul>
    );
  } else {
    return (
      <React.Fragment>
        <ul style={{ margin: 0, padding: 0 }}>
          <OperationMetrics ops={props.ops} />
        </ul>
        <hr style={{ margin: "0.25em", padding: 0, border: null, borderBottom: "1px solid black" }} />
        <ul style={{ margin: 0, padding: 0 }}>
          <OperationMetrics ops={props.ops} numerator={false} />
        </ul>
      </React.Fragment>
    );
  }
};

export const checkScope = (sigmas, baseMetrics) => {
  // If the indicator is not setup for the project overview panel, the indicator in the indicators panel
  // will be greyed out and defined as 'Out of Scope'-- Jira: ASS-260
  return false;
  let outOfScope = true;
  for (let op of ops) {
    if (op.metricIds.length) {
      outOfScope = false;
    }
  }
  return outOfScope;
};

const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const nextSigma = (sigmas) => {
  return ALPHABET[sigmas.size];
};

const prettyArgs = (args, sigmas) => {
  let _args = args.map((i) => {
    let [eqn, _sigmas] = pretty(i, sigmas);
    sigmas = sigmas.merge(_sigmas);
    return eqn;
  });
  return [_args, sigmas];
};

const _mult = (op, sigmas) => {
  let _a = op.get("args"); //.filter(i => i != 1)
  if (_a.includes(0)) {
    return [0, sigmas];
  }
  let [args, _sigmas] = prettyArgs(_a, sigmas);
  if (args.size == 2 && args.get(0) == -1) {
    return [` - ${args.get(1)}`, _sigmas];
  }
  return [`( ${args.join(" * ")} )`, _sigmas];
};

const _div = (op, sigmas) => {
  let [args, _sigmas] = prettyArgs(op.get("args"), sigmas);
  return [`( ${args.join(" / ")} )`, _sigmas];
};

const joinSigns = (exp) => exp.replaceAll(/\+\s*-/g, "-").replaceAll("+-", "-");

const _sum = (op, sigmas) => {
  let _a = op.get("args");
  if (_a.every((i) => isStr(i))) {
    let _var = nextSigma(sigmas);
    return [_var, sigmas.set(_var, _a)];
  }
  let [args, _sigmas] = prettyArgs(_a, sigmas);
  let eqn = joinSigns(`( ${args.join(" + ")} )`);
  return [eqn, _sigmas];
};

const _pow = (op, sigmas) => {
  let _a = op.get("args");
  let [args, _sigmas] = prettyArgs(Im.fromJS([_a.get(0)]), sigmas);
  return [`${args.get(0)} ^ ${_a.get(1)}`];
};

const PRETTY_MAP = {
  mult: _mult,
  div: _div,
  sum: _sum,
  pow: _pow,
};

const stripBrackets = (eqn) => {
  if (!eqn) {
    return eqn;
  }
  let _eqn = String(eqn).trim();
  if (_eqn.startsWith("(") && _eqn.endsWith(")")) {
    return stripBrackets(_eqn.substring(1, _eqn.length - 1));
  }
  return eqn;
};

const pretty = (op, sigmas = Im.Map()) => {
  if (Im.isMap(op)) {
    let _type = op.get("operation");
    let [eqn, _sigmas] = PRETTY_MAP[_type](op, sigmas);
    return [eqn, _sigmas];
  }
  return [op, sigmas];
};

function BaseMetricListItem({ bm }) {
  if (!bm) {
    return null;
  }
  return (
    <li>
      <span className="tooltip_parent">
        {bm.get("name")}
        <span className="tooltiptext">{bm.get("uid").substring(0, 8)}</span>
      </span>
    </li>
  );
}

const idsToBaseMetrics = (metricIds, baseMetrics) => metricIds.map((id) => baseMetrics.get(id)).filter((i) => i);

function MetricIdsToNames({ metricIds, baseMetrics }) {
  let _metrics = idsToBaseMetrics(metricIds, baseMetrics);
  if (!_metrics || !_metrics.size) {
    return <li>Out of scope</li>;
  }
  return _metrics.map((bm, i) => <BaseMetricListItem key={i} bm={bm} />);
}

function Sigmas({ sigmas, baseMetrics }) {
  return sigmas.keySeq().map((key, i) => (
    <React.Fragment key={i}>
      <p className="m-0 p-0">
        <big>
          <b>{key}</b>
        </big>
      </p>
      <ul className="m-0 pl-4">
        <MetricIdsToNames metricIds={sigmas.get(key)} baseMetrics={baseMetrics} />
      </ul>
    </React.Fragment>
  ));
}

function PrettyFunction({ eqn, sigmas, baseMetrics }) {
  return (
    <React.Fragment>
      <ul style={{ margin: 0, padding: 0 }}>
        <big>
          <b>{eqn}</b>
        </big>
      </ul>
      <hr style={{ margin: "0.25em", padding: 0, border: null, borderBottom: "1px solid black" }} />
      <Sigmas sigmas={sigmas} baseMetrics={baseMetrics} />
    </React.Fragment>
  );
}

export default function IndicatorRow({ baseMetrics, indicator, dropDownLinks, setTargetComponent }) {
  if (!baseMetrics) {
    return null;
  }

  let metricTarget = indicator.get("metricTarget");
  let targetValue = metricTarget ? metricTarget.get("value") : null;
  let raThreshold = metricTarget ? metricTarget.getInPath("meta_json.thresholdLow") : null;
  let greenBelow = metricTarget ? metricTarget.getInPath("meta_json.greenBelow") : null;

  // Useful for injecting into the UI for debugging
  let idStr = indicator.get("uid").substring(0, 8);

  // Get the equation in pretty print
  let [eqn, sigmas] = pretty(indicator.get("operation_json"));
  eqn = stripBrackets(eqn);

  let outOfScope = checkScope(sigmas, baseMetrics);
  // If the indicator is not setup for the project overview panel, the indicator in the indicators panel
  // will be greyed out and defined as 'Out of Scope'-- Jira: ASS-260

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-sm-4">
          <span className="align-middle tooltip_parent" style={{ color: outOfScope ? "grey" : "#333" }}>
            {indicator.getInPath("resultBaseMetric.name")}
            <span className="tooltiptext">{idStr}</span>
          </span>
        </div>
        <div className="col-sm-2 align-middle" style={{ color: outOfScope ? "grey" : "#333" }}>
          {indicator.getInPath("resultBaseMetric.category")}
        </div>
        <div className="col-sm-4 align-middle" style={{ color: outOfScope ? "grey" : "#333" }}>
          <PrettyFunction eqn={eqn} sigmas={sigmas} baseMetrics={baseMetrics} outOfScope={outOfScope} />
        </div>
        <div className="col-sm-1 align-middle" style={{ color: outOfScope ? "grey" : "#333" }}>
          <TargetValueDisplay targetValue={targetValue} raThreshold={raThreshold} greenBelow={greenBelow} />
        </div>
        <div className="col-sm-1 align-middle">
          <BSBtnDropdown
            links={dropDownLinks}
            header={
              <span>
                <Octicon name="gear" />
              </span>
            }
          />
        </div>
      </div>
      {setTargetComponent}
      <hr />
    </React.Fragment>
  );
}
