import React from "react";
import { Link } from "react-router-dom";
import { RightArrow } from "traec-react/utils/entities";

export class BreadCrumb extends React.Component {
  render_company() {
    let { company } = this.props;
    if (!company) {
      return null;
    }
    let companyId = company.get("uid");
    companyId = companyId ? companyId.substring(0, 8) : companyId;
    return !company ? null : <Link to={`/company/${companyId}`}>{company.get("name")}</Link>;
  }

  render_project() {
    let { project, isRootRef } = this.props;
    if (!project) {
      return null;
    }
    let projectId = project.get("uid");
    projectId = projectId ? projectId.substring(0, 8) : projectId;
    let projectUrl = project ? `/project/${projectId}` : null;
    return (
      <React.Fragment>
        <RightArrow />
        &nbsp;&nbsp;
        {isRootRef && project ? project.get("name") : <Link to={projectUrl}>{project.get("name")}</Link>}
      </React.Fragment>
    );
  }

  render_report() {
    let { isRootRef, cref } = this.props;
    if (isRootRef || !cref) {
      return null;
    }
    return (
      <React.Fragment>
        <RightArrow />
        &nbsp;&nbsp;
        {cref.get("name")}
      </React.Fragment>
    );
  }

  render() {
    return (
      <p>
        {this.render_company()}
        &nbsp;&nbsp;
        {this.render_project()}
        &nbsp;&nbsp;
        {this.render_report()}
      </p>
    );
  }
}
