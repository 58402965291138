import React from "react";

export const workPackageDetailsFields = {
  name: { value: "", class: "col", endRow: true },
  commit__reporting_period: { label: "Set the current Reporting Period", value: "", inputType: "select", class: "col" },
  commit__due_date: {
    label: "Due date: ",
    value: "",
    inputType: "date",
    class: "col",
    helpText: "Due dates for the following reporting periods will be set accordingly",
  },
  commit__discipline: {
    label: "Appoint reporter for this package",
    value: "",
    inputType: "select",
    class: "col",
    endRow: true,
  },
};

export const workPackageFields = {
  name: { value: "", class: "col", endRow: true },
  latest_commit__reporting_period: {
    label: "Set the current Reporting Period",
    value: "",
    inputType: "select",
    class: "col",
  },
  latest_commit__due_date: {
    label: "Due date: ",
    value: "",
    inputType: "date",
    class: "col",
    helpText: "Due dates for the following reporting periods will be set accordingly",
  },
  latest_commit__discipline: {
    label: "Appoint reporter for this package",
    value: "",
    inputType: "select",
    class: "col",
    endRow: true,
  },
};
