import React, { useState } from "react";
import Traec from "traec";
import { Link } from "react-router-dom";

import Octicon from "react-octicon";
import { CompanyPermission } from "traec/utils/permissions/company";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";
import { ErrorBoundary } from "traec-react/errors";

import { MiniErrorBoundary } from "AppSrc/project/report/error";
import { companyFields } from "AppSrc/company/children";
import { projectFields, setFetchBody } from "AppSrc/project/form";
import CompanyProjectForm from "AppSrc/company/projectForm";

import { setAndShowModal } from "AppSrc/utils/modal";

function CompanyProjectList({ company }) {
  let projectList = company.get("projects") || Traec.Im.List();
  return projectList.map((project, i) => {
    let projectId = project.get("uid");
    if (!project) {
      return null;
    }
    return (
      <p key={i} className={`m-0 p-0 mr-2 pr-2`}>
        <Link to={`/project/${projectId.substring(0, 8)}`}>
          <Octicon name="tools" /> <i>{project.get("name")}</i>
        </Link>
      </p>
    );
  });
}

function SubCompanyList({ company, companyList, currentId }) {
  let childIds = company.get("childids");
  if (!childIds) {
    return null;
  }
  return childIds.map((childId, i) => (
    <CompanyTreeRow key={i} company={companyList.get(childId)} companyList={companyList} currentId={currentId} />
  ));
}

const addSubCompany = (company) => {
  let modalId = "CommonCompanyModal001";

  // Get the fetch for adding a business unit
  let fetch = new Traec.Fetch("company", "post");
  let styles = (company.getInPath("meta_json.styles") || Traec.Im.Map()).toJS();
  fetch.updateFetchParams({
    preFetchHook: (body) => ({
      ...body,
      parentid: company.get("uid"),
      meta_json: {
        styles,
      },
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  setAndShowModal(modalId, {
    title: "Add a Business Unit",
    body: <BaseFormConnected params={fetch.params} fields={companyFields} forceShowForm={true} hideUnderline={true} />,
  });
};

function CompanyAdminDropdown({ companyId, company }) {
  if (!company || !companyId) {
    return null;
  }

  let modalId = `${companyId}-sidebar-modal`;
  let _companyId = companyId.substring(0, 8);

  // Get the fetch for adding a business unit
  let fetch = new Traec.Fetch("company", "post");
  let styles = (company.getInPath("meta_json.styles") || Traec.Im.Map()).toJS();
  fetch.updateFetchParams({
    preFetchHook: (body) => ({
      ...body,
      parentid: company.get("uid"),
      meta_json: {
        styles,
      },
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  return (
    <MiniErrorBoundary>
      <BSBtnDropdown
        header={" "}
        floatStyle={"sidebar-dropdown-text float-right"}
        links={[
          {
            name: "Add a Business Unit",
            onClick: (e) => addSubCompany(company),
          },
          {
            name: "Add a Project",
            onClick: (e) => {
              setAndShowModal("CommonCompanyModal001", {
                title: "Add a Project",
                body: <CompanyProjectForm companyId={companyId} company={company} modalId={modalId} />,
              });
            },
          },
          { name: "Go to Settings", linkTo: `/company/${_companyId}/details` },
        ]}
      />
    </MiniErrorBoundary>
  );
}

export default class CompanyTreeRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formParams: {},
      formFields: companyFields,
    };
  }

  render() {
    let { company, currentId } = this.props;
    if (!company) {
      return null;
    }

    let companyId = company.get("uid");
    let isCurrent = companyId === currentId;
    let bgColor = isCurrent ? "bg-info" : "";

    let _companyId = companyId ? companyId.substring(0, 8) : null;

    return (
      <div className="m-0 ml-2">
        <div className={`row m-0 p-0 ${bgColor}`}>
          <p className={`m-0 p-0 mr-2 col`} style={{ display: "inline-block", verticalAlign: "middle" }}>
            <Link to={`/company/${_companyId}`}>
              <Octicon name="server" /> {company.get("name")}
            </Link>
          </p>
          {this.props.extraContent}

          <MiniErrorBoundary>
            {isCurrent ? (
              <CompanyPermission companyId={currentId} requiresAdmin={true}>
                <CompanyAdminDropdown companyId={companyId} company={company} />
              </CompanyPermission>
            ) : null}
          </MiniErrorBoundary>
        </div>
        {/* Project list */}
        <CompanyPermission companyId={currentId} requiresAdmin={false} requiredActions={["READ_COMPANY_PROJECT"]}>
          <div className="m-0 p-0 ml-2">
            <CompanyProjectList company={company} />
          </div>
        </CompanyPermission>

        {/* Render the sub-elements */}
        <SubCompanyList {...this.props} />
      </div>
    );
  }
}
