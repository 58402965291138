import React from "react";
import VERSION from "AppAssets/text/VERSION.txt";
import BUILD_DATE from "AppAssets/text/BUILD_DATE.txt";

export const Footer = function () {
  return (
    <div className="footer">
      <div className="footer-inner row">
        <div className="col-sm-3">2019 © Action Sustainability | 0207 697 1984</div>
        <div className="col-sm-6 text-center center-block">
          <a
            className="text-white"
            href="https://docs.sustainabilitytool.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            User Guide
          </a>
        </div>
        <div className="col-sm-3 text-right">
          {VERSION.slice(0, 8)} {BUILD_DATE}
        </div>
      </div>
    </div>
  );
};
