import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Traec from "traec";

import { BSCard, BSBtn, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import { CompanyTree } from "./tree";

export const companyFields = {
  name: { value: "", class: "col", endRow: true }, //,
  //address: { value: "", class: "col", endRow: true },
  //address2: { value: "", class: "col", endRow: true },
  //suburb: { value: "", class: "col" },
  //postcode: { value: "", class: "col" },
  //state: { value: "", class: "col" },
  //country: { value: "", class: "col", endRow: true }
};

function CompanyChildren(props) {
  let { company, companyList } = props;
  if (!company) {
    return null;
  }

  let modalId = `ADD_COMPANY_${company ? company.get("uid") : null}`;
  let styles = (company.getInPath("meta_json.styles") || Traec.Im.Map()).toJS();

  let fetch = new Traec.Fetch("company", "post");
  fetch.updateFetchParams({
    preFetchHook: (body) => ({
      ...body,
      parentid: company.get("uid"),
      meta_json: {
        styles,
      },
    }),
    postSuccessHook: () => {
      $(`#${modalId}`).modal("hide");
    },
  });

  return (
    <BSCard
      widthOffset="col-sm-12"
      title="Business Units"
      button={
        <BSBtn
          onClick={(e) => {
            $(`#${modalId}`).modal("show");
          }}
          text="Add a Business Unit"
        />
      }
      body={<CompanyTree fromHere={true} company={company} companyList={companyList} currentId={company.get("uid")} />}
      form={
        <BSModal
          id={modalId}
          title="Add a Business Unit"
          body={
            <BaseFormConnected params={fetch.params} fields={companyFields} forceShowForm={true} hideUnderline={true} />
          }
        />
      }
    />
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    companyList: state.getInPath("entities.companies.byId"),
  };
};

export default connect(mapStateToProps)(CompanyChildren);
