import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Traec from "traec";

import { BSCard, BSBtn, BSModal } from "traec-react/utils/bootstrap";
import BaseFormConnected from "traec-react/utils/form";

import { workPackageFields } from "./form";
import WPTree from "./index";
import { ErrorBoundary } from "traec-react/errors/handleError";
import { getTerm } from "AppSrc/tree/utils";

/* A component that gives the work packages of a work package
with a form to create more work packages as requried.

Effectively you are just created nested "categories" in the
tracker.
*/

class WorkPackageList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formParams: {
        stateParams: {},
        fetchParams: {},
        initFields: {},
      },
    };
  }

  getURLparams() {
    let { project, tracker, cref, rootTree } = this.props;
    let commit = cref ? cref.get("latest_commit") : null;
    return {
      projectId: project ? project.get("uid") : null,
      trackerId: tracker ? tracker.get("uid") : null,
      refId: cref ? cref.get("uid") : null,
      commitId: commit ? commit.get("uid") : null,
      rootTreeId: rootTree ? rootTree.get("uid") : null,
    };
  }

  getFetch() {
    let { projectId } = this.props;
    let { trackerId, refId, rootCommitId, commitId, rootTreeId: treeId } = this.getURLparams();
    let fromCommitId = commitId || rootCommitId;

    let fetch = new Traec.Fetch("tracker_ref_tree_branch", "post", {
      trackerId,
      refId,
      commitId,
      treeId,
      skip_categories: true,
    });
    fetch.updateFetchParams({
      preFetchHook: (body) => {
        let _body = {
          ...body,
          ref_name: body.name || "master",
          from_commit: fromCommitId,
        };
        console.log("CREATING REF WITH PARAMETERS", _body);
        return _body;
      },
      postSuccessHook: () => {
        $(`#${projectId}`).modal("hide");
      },
    });

    return fetch;
  }

  render() {
    let { projectId, project, tracker, rootRef, cref, showMenu, tenant_meta } = this.props;

    if (!project) {
      return null;
    }

    let fetch = this.getFetch();
    let rp_term = getTerm("Reporting Package", this.props);

    return (
      <ErrorBoundary>
        <BSCard
          widthOffset="col-sm-12"
          title={`${rp_term}s in Project`}
          button={
            <BSBtn
              onClick={() => {
                $(`#${projectId}`).modal("show");
              }}
              text={`Add a ${rp_term}`}
            />
          }
          body={
            <WPTree
              projectId={projectId}
              tracker={tracker}
              rootRef={rootRef}
              cref={cref}
              showMenu={showMenu}
              tenant_meta={tenant_meta}
            />
          }
          form={
            <BSModal
              id={projectId}
              title={`Add a ${rp_term}`}
              body={
                <BaseFormConnected
                  params={fetch.params}
                  fields={workPackageFields}
                  forceShowForm={true}
                  hideUnderline={true}
                />
              }
            />
          }
        />
      </ErrorBoundary>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  let { project, tracker, cref } = ownProps;
  let commit = cref ? cref.get("latest_commit") : null;
  // Get the root ref from the tracker
  let rootRefId = tracker ? tracker.get("root_master") : null;
  let rootRef = rootRefId ? state.getInPath(`entities.refs.byId.${rootRefId}`) : null;
  let rootCommitId = rootRef ? rootRef.getInPath("latest_commit.uid") : null;
  // Get the root tree from the cref
  let rootTree = null;
  if (commit) {
    rootTree = commit.get("tree_root");
  }
  let projectId = project ? project.get("uid") : null;
  return { projectId, rootRef, rootCommitId, commit, rootTree };
};

export default connect(mapStateToProps)(WorkPackageList);
